<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Project Name
            </th>
            <th class="text-uppercase">
              District/Club
            </th>
            <th class="text-uppercase">
              Date
            </th>
            <th class="text-uppercase">
              Location
            </th>
            <th class="text-uppercase">
              Recipients
            </th>
            <th class="text-uppercase">
              Particulars
            </th>
            <th class="text-uppercase">
              Amount/In Kind
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td>
              {{ item.project.name_of_the_project}}
            </td>
            <td>
              {{ item.project.distict.district_name }}
            </td>
            <td>
              {{ item.project.date_of_project }}
            </td>
            <td>
              {{ item.project.location }}
            </td>
            <td>
              {{ item.project.recipients }}
            </td>
            <td>
              {{ parseInt(item.amount)===0?'Others':(item.project.distict!=''?'Money':'') }}
            </td>
            <td>
              {{parseInt(item.amount)===0?item.kind:(item.project.distict!=''?formatPrice(item.amount):'')}}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>

  export default {
    props: {
      data: Array,
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    }
  }
</script>
