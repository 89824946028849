<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light" v-if="position!='USER'">SEARCH MEMBERS</h4>
          <h4 class="font-weight-light" v-else>PERSONAL INFORMATION</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="position!='USER'">
        <v-combobox
          class="mt-6"
          :append-icon="icons.mdiAccountSearchOutline"
          v-model="search"
          @keyup="searching($event.target.value)"
          @change="get_search_items_info"
          :items="search_items"
          item-value="id"
          item-text="name"
          placeholder="Search by (Account Name/Account #)"
          ref="memberCombobox"
          dense
          outlined
        ></v-combobox>
      </v-card-text>
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <account
            :cropped="cropped"
            :branch="branch"
            :data="member_information"
            :district_history="district_history"
            :latest_sector="latest_sector"
            :latest_district="latest_district"
            :is_admin="position==='ADMIN'"
            v-on:data="on_update_change_type"
          ></account>
        </v-tab-item>
        <v-tab-item>
          <induction
            :data="induction_history"
          ></induction>
        </v-tab-item>
        <v-tab-item>
          <foundation
            :info="member_information"
            :data="foundation_history"
          ></foundation>
        </v-tab-item>
        <v-tab-item>
          <district-club
            :data="district_history"
          ></district-club>
        </v-tab-item>
        <v-tab-item>
          <donation
            :data="donation_history"
          ></donation>
        </v-tab-item>
        <v-tab-item>
          <participation
            :data="participation_history"
          ></participation>
        </v-tab-item>
        <v-tab-item>
          <inventory
            :data="inventory_history"
          ></inventory>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiInformationOutline, mdiAccountCashOutline
    , mdiCash, mdiCellphone, mdiClipboardClockOutline, mdiContentSaveMoveOutline,
    mdiCashRefund, mdiShieldAccount, mdiGoogleCirclesCommunities, mdiBallotOutline,
    mdiCharity, mdiHandshakeOutline, mdiCubeScan, mdiGift,
  } from '@mdi/js'
  import moment from 'moment'
  import {mapGetters, mapActions} from "vuex";
  import Account from './member_components/Account'
  import Induction from './member_components/Induction'
  import Foundation from './member_components/Foundation'
  import DistrictClub from './member_components/DistrictClub'
  import Donation from './member_components/Donation'
  import Participation from './member_components/Participation'
  import Inventory from './member_components/Inventory'

  export default {
    components: {
      Account,
      Induction,
      Foundation,
      DistrictClub,
      Donation,
      Participation,
      Inventory,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
          mdiCharity,
          mdiHandshakeOutline,
          mdiCubeScan,
          mdiGift,
        },
      }
    },
    data() {
      return {
        search: '',
        search_member: '',
        search_items: [],

        cropped: this.avatarImg,
        branch: '',
        member_information: {},
        district_history: [{district: ''}],
        latest_sector: {district: ''},
        latest_district: {district: ''},
        foundation_history: [],
        induction_history: [],
        donation_history: [{project: {distict: ''},}],
        participation_history: [{project: {distict: ''},}],
        inventory_history: [],
        tab: '',
        tabs: [
          {title: 'INFORMATION', icon: mdiAccountOutline},
          {title: 'INDUCTION HISTORY', icon: mdiGoogleCirclesCommunities},
          {title: 'PAYMENT HISTORY', icon: mdiGoogleCirclesCommunities},
          {title: 'POSITION & DISTRICT HISTORY', icon: mdiCharity},
          {title: 'DONATION HISTORY', icon: mdiGift},
          {title: 'PARTICIPATION HISTORY', icon: mdiHandshakeOutline},
          {title: 'INVENTORY HISTORY', icon: mdiCubeScan},
        ],
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
          this.member_information = {}
          this.district_history = [{district: ''}]
          this.latest_sector = {district: ''}
          this.latest_district = {district: ''}
          this.foundation_history = []
          this.induction_history = []
          this.donation_history = [{project: {distict: ''},}]
          this.participation_history = [{project: {distict: ''},}]
          this.inventory_history = []
        }, 500)
      },
    },
    mounted() {
      if (this.position === 'USER') {
        this.search_all_members_by_id({
          search_word: this.member_id,
        })
          .then(response => {
            this.search_items = response.data
            if (this.search_items.length > 0) {
              this.member_information = {}
              this.district_history = [{district: ''}]
              this.latest_sector = {district: ''}
              this.latest_district = {district: ''}
              this.foundation_history = []
              this.induction_history = []
              this.donation_history = [{project: {distict: ''},}]
              this.participation_history = [{project: {distict: ''},}]
              this.inventory_history = []
              var index = 0
              this.district_history = [{district: ''}]
              this.latest_sector = {district: ''}
              this.latest_district = {district: ''}
              this.cropped = this.search_items[index].image
              this.branch = this.search_items[index].branch
              this.member_information = this.search_items[index].member
              if (this.search_items[index].district_history.length > 0) {
                this.district_history = this.search_items[index].district_history
              }
              this.latest_sector = this.search_items[index].latest_sector
              this.latest_district = this.search_items[index].latest_district
              this.induction_history = this.search_items[index].induction_history
              this.foundation_history = this.search_items[index].member_payment
              if (this.search_items[index].donation_history.length > 0) {
                this.donation_history = this.search_items[index].donation_history
              }
              if (this.search_items[index].participation_history.length > 0) {
                this.participation_history = this.search_items[index].participation_history
              }
              if (this.search_items[index].inventory_history.length > 0) {
                this.inventory_history = this.search_items[index].inventory_history
              }
            }
          })
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position', 'district_id', 'member_id']),
    },
    methods: {
      ...mapActions('members_information', ['search_all_members', 'search_all_members_by_id']),
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_all_members({
          search_word: value,
          district_id: this.district_id,
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      on_update_change_type() {
        this.searching_search_items(this.search_member)
      },
      reset() {
        this.cropped = this.avatarImg
        this.branch = ''
      },
      get_search_items_info() {
        this.amount = 0
        this.amount_due = []
        this.member_information = {}
        this.district_history = [{district: ''}]
        this.latest_sector = {district: ''}
        this.latest_district = {district: ''}
        this.foundation_history = []
        this.donation_history = [{project: {distict: ''},}]
        this.participation_history = [{project: {distict: ''},}]
        this.inventory_history = []

        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.district_history = [{district: ''}]
            this.latest_sector = {district: ''}
            this.latest_district = {district: ''}
            this.cropped = this.search_items[index].image
            this.branch = this.search_items[index].branch
            this.member_information = this.search_items[index].member
            if (this.search_items[index].district_history.length > 0) {
              this.district_history = this.search_items[index].district_history
            }
            this.latest_sector = this.search_items[index].latest_sector
            this.latest_district = this.search_items[index].latest_district
            this.induction_history = this.search_items[index].induction_history
            this.foundation_history = this.search_items[index].member_payment
            if (this.search_items[index].donation_history.length > 0) {
              this.donation_history = this.search_items[index].donation_history
            }
            if (this.search_items[index].participation_history.length > 0) {
              this.participation_history = this.search_items[index].participation_history
            }
            if (this.search_items[index].inventory_history.length > 0) {
              this.inventory_history = this.search_items[index].inventory_history
            }
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
    }
  }
</script>
