<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Date
            </th>
            <th class="text-uppercase">
              Particulars
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td>
              {{ item.date}}
            </td>
            <td>
              {{ item.particulars.particulars }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>

  export default {
    props: {
      data: Array,
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    }
  }
</script>
