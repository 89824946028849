<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Amount
            </th>
            <th class="text-center text-uppercase">
              Year Of
            </th>
            <th class="text-center text-uppercase">
              Remarks
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              {{ item.year_of }}
            </td>
            <td class="text-center">
              {{ item.remarks }}
            </td>
            <td class="text-center">
              <div v-if="item.deposit_slip_id!=null">
                <v-chip color="success" dark>PAID</v-chip>
              </div>
              <div v-else>
                <v-chip color="error" dark>UNPAID</v-chip>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    props: {
      info: Object,
      data: Array,
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    }
  }
</script>
