<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-text class="d-flex">
        <v-avatar
          rounded
          size="120"
          class="me-6"
        >
          <v-img :src="croppedd"></v-img>
        </v-avatar>
        <!-- upload photo -->
        <div>
          <v-btn
            color="primary"
            class="me-3 mt-5"
            @click="$refs.refInputEl.click()"
          >
            <v-icon class="d-sm-none">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
            <span class="d-none d-sm-block">Upload new photo</span>
          </v-btn>

          <input
            ref="refInputEl"
            type="file"
            accept=".jpeg,.png,.jpg"
            :hidden="true"
            v-on:click="$refs.refInputEl.value = ''"
            v-on:change="croppie"
          />

          <v-btn
            color="error"
            outlined
            class="mt-5"
            @click="resett()"
          >
            Reset
          </v-btn>
          <p class="text-sm mt-5">
            Allowed JPG or PNG
          </p>
        </div>
        <v-flex md2>
          <div>
            <v-text-field readonly
                          v-model="data.type_of_member"
                          label="Type of Member"
                          dense
                          outlined
            ></v-text-field>
            <v-btn outlined
                   class="w-full"
                   color="success"
                   @click="print_certificates()"
                   v-if="Object.keys(data).length != 0 && data.type_of_member==='Regular' && data.paid===1"
            >
              Print Certificate
            </v-btn>
            <v-btn outlined
                   class="w-full"
                   color="warning"
                   @click="change_type()"
                   v-if="position==='ADMIN' && Object.keys(data).length != 0"
            >
              Change Type of Member
            </v-btn>
            <v-btn outlined
                   class="w-full mt-2"
                   color="error"
                   @click="delete_members_data()"
                   v-if="position==='ADMIN' && Object.keys(data).length != 0"
            >
              Delete Member
            </v-btn>
          </div>
        </v-flex>
        <v-flex md6>
          <div class="mx-2">
            <v-text-field readonly
                          v-model="sector_name"
                          label="Sector"
                          dense
                          outlined
            ></v-text-field>
            <v-text-field readonly
                          v-model="district_name"
                          label="District/Club"
                          dense
                          outlined
            ></v-text-field>

            <v-btn outlined
                   class="w-full"
                   color="info"
                   @click="change_district()"
                   v-if="position==='ADMIN' && Object.keys(data).length != 0"
            >
              Change District/Club and Position
            </v-btn>
          </div>
        </v-flex>
        <v-flex md3>
          <div>
            <v-text-field readonly
                          v-model="sector_position"
                          label="Sector Position"
                          dense
                          outlined
            ></v-text-field>
            <v-text-field readonly
                          v-model="district_position"
                          label="Position"
                          dense
                          outlined
            ></v-text-field>
          </div>
        </v-flex>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="12"
              cols="12"
            >

            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field readonly
                            v-model="data.date_accomplished"
                            label="Date Accomplished"
                            dense
                            outlined

              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field readonly
                            v-model="data.cmf_no"
                            label="CMF No."
                            dense
                            outlined
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >

            </v-col>
          </v-row>
          <v-row>
            <div v-if="!is_admin">
              <v-card
                flat
              >
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-row>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field readonly
                                    v-model="data.last_name"
                                    label="Last Name"
                                    dense
                                    outlined

                      ></v-text-field>
                    </v-col>

                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field readonly
                                    v-model="data.first_name"
                                    label="First Name"
                                    dense
                                    outlined

                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field readonly
                                    v-model="data.middle_name"
                                    label="Middle Name"
                                    dense
                                    outlined

                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field readonly
                                    v-model="data.date_of_birth"
                                    label="Date of Birth"
                                    dense
                                    outlined

                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="data.place_of_birth"
                        dense
                        label="Place of Birth"
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field readonly
                                    v-model="data.gender"
                                    dense
                                    label="Gender"
                                    outlined

                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="data.contact_no"
                        dense
                        outlined
                        label="Contact Number/s"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="data.email_address"
                        dense
                        outlined
                        label="Email Address"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field readonly
                                    v-model="data.civil_status"
                                    dense
                                    label="Civil Status"
                                    outlined

                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="3"
                      cols="12"
                    >
                      <v-text-field
                        v-model="data.occupation"
                        label="Occupation"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="10"
                    >
                      <v-text-field
                        v-model="data.permanent_address"
                        dense
                        outlined
                        label="Permanent Address"
                        :rules="rules.default_max_255_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="data.zip_code"
                        dense
                        outlined
                        label="Zip Code"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" v-if="data.id===member_id">
                      <v-btn color="primary" class="me-3 mt-4" @click="save_member" v-if="!saving">
                        Save
                        changes
                      </v-btn>
                      <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                           v-else></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <div v-else>
              <v-card
                flat
              >
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-row>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="last_name"
                        label="Last Name"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>

                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="first_name"
                        label="First Name"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="middle_name"
                        label="Middle Name"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        type="date"
                        v-model="date_of_birth"
                        label="Date of Birth"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="place_of_birth"
                        dense
                        label="Place of Birth"
                        outlined

                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-select
                        v-model="gender"
                        dense
                        outlined
                        label="Gender"
                        :items="['Male','Female']"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="contact_no"
                        dense
                        outlined
                        label="Contact Number/s"

                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="email_address"
                        dense
                        outlined
                        label="Email Address"

                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-select
                        v-model="civil_status"
                        dense
                        outlined
                        label="Civil Status"
                        :items="['Single','Married','Widowed','Separated']"
                      ></v-select>
                    </v-col>
                    <v-col
                      md="3"
                      cols="12"
                    >
                      <v-text-field
                        v-model="occupation"
                        label="Occupation"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="10"
                    >
                      <v-text-field
                        v-model="permanent_address"
                        dense
                        outlined
                        label="Permanent Address"

                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="zip_code"
                        dense
                        outlined
                        label="Zip Code"

                      ></v-text-field>
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12" v-if="is_admin">
              <v-btn color="primary" class="me-3 mt-4" @click="save_member" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog v-model="is_change_type_member" persistent max-width="80%">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHANGE TYPE OF MEMBER</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-row>
          <v-col
            md="12"
            cols="12"
          >
            <v-select
              class="mx-2 mt-2"
              v-model="type_of_member"
              dense
              outlined
              label="Type of Member"
              :items="['Beneficiary','Regular','Volunteer','Scholar']"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn color="primary" @click="save_change_type"> Save Changes</v-btn>
          <v-btn color="error" @click="is_change_type_member = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_change_district" persistent max-width="80%">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHANGE DISTRICT/CLUB AND POSITION</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-row>
          <v-col
            md="9"
            cols="12"
          >
            <v-select
              class="mx-2"
              v-model="district"
              dense
              outlined
              label="District/Club"
              :items="district_items"
              item-value="id"
              item-text="district_name"
              :rules="rules.combobox_rule"
              @change="new_position='MEMBER'"
            ></v-select>
          </v-col>
          <v-col
            md="3"
            cols="12"
          >
            <v-select
              class="mx-2"
              v-model="new_position"
              dense
              outlined
              label="Position"
              :items="['PRESIDENT','VICE PRESIDENT','TREASURY','SECRETARY','AUDITOR 1','AUDITOR 2','COUNCIL 1','COUNCIL 2','COUNCIL 3','COUNCIL 4','COUNCIL 5','ADVISER','MEMBER']"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-col>
          <v-col
            md="3"
            cols="12"
            class="mx-2"
          >
            <v-text-field
              v-model="effective"
              label="Effective Date"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
              @change="date_actions"
            ></v-text-field>
          </v-col>
          <v-col
            md="3"
            cols="12"
          >
            <v-text-field
              v-model="expiration"
              label="Expiration Date"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn color="primary" @click="save_change_districy"> Save Changes</v-btn>
          <v-btn color="error" @click="is_change_district = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
        croppedd: '',
        croppieImage: '',
        avatarImg: require('@/assets/images/avatars/1.png'),

        type_of_member: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        date_of_birth: '',
        place_of_birth: '',
        gender: '',
        contact_no: '',
        email_address: '',
        civil_status: '',
        district: '',
        occupation: '',
        effective: '',
        expiration: '',
        new_position: '',
        permanent_address: '',
        zip_code: '',
        sector_name: '',
        sector_position: '',
        district_name: '',
        district_position: '',
        district_items: [],

        saving: false,
        alert: false,
        alert_message: '',

        is_change_type_member: false,
        is_change_district: false,
      }
    },
    props: {
      cropped: String,
      branch: String,
      data: Object,
      latest_sector: Object,
      latest_district: Object,
      district_history: Array,
      is_admin: Boolean,
    },
    watch: {
      data: function () {
        if (this.latest_sector!=null) {
          this.sector_name = this.latest_sector.district.district_name
          this.sector_position = this.latest_sector.position
        }
        if (this.latest_district!=null) {
          this.district_name = this.latest_district.district.district_name
          this.district_position = this.latest_district.position
        }
        this.croppedd = this.cropped
        this.last_name = this.data.last_name
        this.first_name = this.data.first_name
        this.middle_name = this.data.middle_name
        this.date_of_birth = moment(this.data.date_of_birth, 'MMMM dd, YYYY').format('yyyy-MM-DD')

        this.place_of_birth = this.data.place_of_birth
        this.gender = this.data.gender
        this.contact_no = this.data.contact_no
        this.email_address = this.data.email_address

        this.civil_status = this.data.civil_status
        this.occupation = this.data.occupation
        this.permanent_address = this.data.permanent_address
        this.zip_code = this.data.zip_code
      }
    },
    created() {
      this.list_of_district()
        .then(response => {
          this.district_items = response.data
        })
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'certificate_regular_member']),
      ...mapGetters('authentication', ['position', 'member_id']),
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['update_details_for_admin_only', 'change_type_of_member', 'delete_member']),
      ...mapActions('district', ['list_of_district']),
      ...mapActions('district_officers_history', ['save_new_officers_district']),
      croppie(e) {
        this.show = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.croppedd = this.croppieImage = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.croppedd = this.avatarImg
        this.croppedd = this.cropped
      },
      save_member() {
        var proceed = true;

        if (this.croppieImage === '') {
          this.alert = true
          this.alert_message = 'PLEASE UPLOAD A NEW PHOTO'
          this.saving = false
          proceed = false;
        }
        const str = this.contact_no;
        const firstTwoChars = str.slice(0, 2);
        if (firstTwoChars != '09') {
          this.alert = true
          this.alert_message = 'CONTACT # MUST START 09'
          this.saving = false
          proceed = false;
        }
        if (!proceed) {
        } else {
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('id', this.data.id)
            data.append('profile_picture', this.croppieImage);
            data.append('last_name', this.last_name.toUpperCase())
            data.append('first_name', this.first_name.toUpperCase())
            data.append('middle_name', this.middle_name.toUpperCase())
            data.append('date_of_birth', this.date_of_birth)
            data.append('place_of_birth', this.place_of_birth.toUpperCase())
            data.append('gender', this.gender)
            data.append('civil_status', this.civil_status)
            data.append('permanent_address', this.permanent_address)
            data.append('zip_code', this.zip_code)
            data.append('contact_no', this.contact_no)
            data.append('email_address', this.email_address)
            data.append('occupation', this.occupation)
            this.update_details_for_admin_only(data)
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving = false
          }
        }
      },
      print_certificates() {
        var imgData = this.company_logo
        var imgcertificate_regular_member = this.certificate_regular_member

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'landscape',
          background: [
            {
              image: imgcertificate_regular_member,
              width: 792,
              height: 615,
            }
          ],
          content: [
            '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
            {
              columns: [
                {
                  stack: [
                    this.data.last_name + ', ' + this.data.first_name + ' ' + this.data.middle_name,
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
          ],
          styles: {
            header: {
              fontSize: 30,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 25
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            printed_label2: {
              alignment: 'center',
              fontSize: 15,
              bold: true,
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
      change_type() {
        this.is_change_type_member = true
        this.type_of_member = this.data.type_of_member
      },
      change_district() {
        this.is_change_district = true
        this.district = this.district_history[0].district.id
        this.new_position = this.district_history[0].position
      },
      save_change_type() {
        this.change_type_of_member({
          member_id: this.data.id,
          type_of_member: this.type_of_member,
        }).then(response => {
          var color = 'success'
          if (response.status === 201) {
            color = 'error'
          }
          this.change_snackbar({
            show: true,
            color: color,
            text: response.data,
          })
          this.is_change_type_member = false
          this.$emit('data', true)
        })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
      date_actions() {
        this.expiration = moment(this.effective, 'YYYY-MM-DD').add(1, 'year').format('MM/DD/YYYY')
      },
      save_change_districy() {
        this.save_new_officers_district({
          member_id: this.data.id,
          position: this.new_position,
          date_started: this.effective,
          expiration_date: this.expiration,
          district_id: this.district,
        }).then(response => {
          var color = 'success'
          if (response.status === 201) {
            color = 'error'
          }
          this.change_snackbar({
            show: true,
            color: color,
            text: response.data,
          })
          this.is_change_district = false
          this.$emit('data', true)
        })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
      delete_members_data() {
        this.delete_member({
          member_id: this.data.id,
        })
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.is_change_type_member = false
            this.$emit('data', true)
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      }
    }
  }
</script>
